<template>
  <div>
    <div class="mt-15 px-8" v-if="isLoad">
      <v-row class="px-15">
        <v-col cols="12" v-for="i in 5" :key="i" class="text-left">
          <v-skeleton-loader type="paragraph" class="mt-3"></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <v-col cols="10" sm="8" class="pa-0 mt-10 pt-10 mx-auto">
        <div class="font-bold text-2xl sm:text-4xl mt-10 pt-10 mb-5">
          <span v-if="bahasa">Dewan Direksi</span>
          <span v-else>Board of Directors</span>
        </div>
      </v-col>

      <!-- Foto Utama -->
      <v-row class="mt-3" v-if="itemPrimary.image">
        <v-col cols="12" sm="8" class="mx-auto">
          <v-card class="pt-0" flat>
            <v-card-text class="pt-0 px-0">
              <img
                v-if="this.itemPrimary.image"
                :src="this.baseUrlImage + this.itemPrimary.image.file_path"
                @error="$event.target.src = 'https://picsum.photos/300/200'"
                class="rounded-md mt-8 max-w-md md:max-w-2xl" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- End Foto Utama -->

      <!-- Slick carousel -->
      <div v-if="item.length > 0">
        <div class="py-10 hidden md:block bg-primaryLight">
          <slick
            ref="slick"
            :options="slickOptionsStatus"
            class="lg:px-10 lg:mx-8 sm:px-8">
            <div
              v-for="(i, index) in item"
              :key="index"
              class="text-left px-3 md:px-10 md:mx-8">
              <div class="bg-white flex justify-center">
                <img
                  :src="baseUrlImage + i.image.file_path"
                  @error="
                    $event.target.src =
                      'https://admin.bpdlh.id/assets/empty.png'
                  "
                  class="rounded-md h-60" />
              </div>
              <div class="text-base py-2 pa-0 font-bold title-statistic">
                {{ i.name || "-" }}
              </div>
              <div class="text-sm pa-0">{{ i.jabatan || "-" }}</div>
              <div class="flex pa-0">
                <v-btn
                  class="btn-primary mt-3 text-sm text-capitalize"
                  @click="openModal(index)">
                  <span v-if="bahasa">selengkapnya</span>
                  <span v-else>more</span>
                </v-btn>
              </div>
            </div>
          </slick>
          <button
            class="px-3 py-3 btn-scroll font-medium ml-3 mb-10 nextStatus-btn">
            <v-icon color="white">arrow_back</v-icon>
          </button>
          <button
            class="px-3 py-3 btn-scroll font-medium ml-3 mb-10 backStatus-btn">
            <v-icon color="white">arrow_forward</v-icon>
          </button>
        </div>
        <!-- Display on sm -->
        <div class="py-10 md:hidden bg-primaryLight">
          <div
            v-for="(i, index) in item"
            :key="index"
            class="text-left px-8 py-5 md:px-10 md:mx-8">
            <div class="bg-white flex justify-center">
              <img
                :src="baseUrlImage + i.image.file_path"
                @error="
                  $event.target.src = 'https://admin.bpdlh.id/assets/empty.png'
                "
                class="rounded-md h-60" />
            </div>
            <div class="text-base py-2 pa-0 font-bold title-statistic">
              {{ i.name || "-" }}
            </div>
            <div class="text-sm pa-0">{{ i.jabatan || "-" }}</div>
            <div class="flex pa-0">
              <v-btn
                class="btn-primary mt-3 text-sm text-capitalize"
                @click="openModal(index)">
                <span v-if="bahasa">selengkapnya</span>
                <span v-else>more</span>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
      <!-- End Slick carousel -->

      <!-- Modal  -->
      <v-dialog v-model="dialog" persistent max-width="600">
        <v-card>
          <img
            v-if="itemActive.image"
            :src="baseUrlImage + itemActive.image.file_path"
            @error="
              $event.target.src = 'https://admin.bpdlh.id/assets/empty.png'
            "
            height="100%"
            class="rounded-md h-60" />
          <v-card-title class="text-h5">
            {{ itemActive.name ? itemActive.name : "" }}
          </v-card-title>
          <v-card-text>
            <p class="text-justify">
              {{ itemActive.jabatan ? itemActive.jabatan : "" }}
            </p>
          </v-card-text>
          <v-card-text>
            <div class="text-justify" v-html="itemActive.deskripsi"></div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="dialog = false">
              <span v-if="bahasa">Tutup</span>
              <span v-else>Close</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- End Modal -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Slick from "vue-slick";
import "slick-carousel/slick/slick.css";
import Swal from "sweetalert2";
export default {
  components: {
    Slick,
  },
  props: {
    bahasa: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dialog: false,
      isLoad: false,
      isLoadPrimary: false,
      item: {},
      itemPrimary: {},
      itemActive: {},
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlImage: process.env.VUE_APP_BASE_ASSET,
      slickOptions: {
        slidesToShow: 3,
        prevArrow: ".next-btn",
        nextArrow: ".back-btn",
      },
      slickOptionsXS: {
        slidesToShow: 1,
        prevArrow: ".next-btn-xs",
        nextArrow: ".back-btn-xs",
      },
      slickOptionsStatus: {
        slidesToShow: 4,
        prevArrow: ".nextStatus-btn",
        nextArrow: ".backStatus-btn",
        dots: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
      slickOptionsStatusXS: {
        slidesToShow: 1,
        prevArrow: ".nextStatusXS-btn",
        nextArrow: ".backStatusXS-btn",
      },
    };
  },
  watch: {
    page() {
      this.getData();
      this.getPrimaryData();
    },
    language() {
      this.getData();
      this.getPrimaryData();
    },
    item(val) {
      this.item = val || [];
    },
  },
  computed: {
    language() {
      return this.bahasa ? "ID" : "ENG";
    },
  },
  mounted() {
    this.getData();
    this.getPrimaryData();
  },
  methods: {
    openModal(index) {
      this.dialog = true;
      this.itemActive = this.item[index];
      // alert(index);
    },
    async getPrimaryData() {
      this.isLoadPrimary = true;

      await axios
        .get(this.baseUrl + "/board_of_director/primary", {
          params: { lang: this.language },
        })
        .then((response) => {
          if (response.data.code == 200 && response.data.success == true) {
            this.itemPrimary = response.data.data;
          }
        })
        .catch((error) => {
          if (error.response.data.code === 404) {
            this.$router.push({ name: "NotFound" });
          } else {
            this.isLoadPrimary = false;
            Swal.fire("Failed!", error.response.data.message, "error");
          }
        })
        .finally(() => (this.isLoadPrimary = false));
    },
    async getData() {
      this.isLoad = true;

      await axios
        .get(this.baseUrl + "/board_of_director", {
          params: { lang: this.language },
        })
        .then((response) => {
          if (response.data.code == 200) {
            this.item = response.data.data;
          }
        })
        .catch((error) => {
          if (error.response.data.code === 404) {
            this.$router.push({ name: "NotFound" });
          } else {
            this.isLoad = false;
            Swal.fire("Failed!", error.response.data.message, "error");
          }
        })
        .finally(() => (this.isLoad = false));
    },
    next() {
      this.$refs.slick.next();
    },
    prev() {
      this.$refs.slick.prev();
    },
    reInit() {
      // Helpful if you have to deal with v-for to update dynamic lists
      this.$nextTick(() => {
        this.$refs.slick.reSlick();
      });
    },
    // Events listeners
    handleAfterChange(event, slick, currentSlide) {
      console.log("handleAfterChange", event, slick, currentSlide);
    },
    handleBeforeChange(event, slick, currentSlide, nextSlide) {
      console.log("handleBeforeChange", event, slick, currentSlide, nextSlide);
    },
    handleBreakpoint(event, slick, breakpoint) {
      console.log("handleBreakpoint", event, slick, breakpoint);
    },
    handleDestroy(event, slick) {
      console.log("handleDestroy", event, slick);
    },
    handleEdge(event, slick, direction) {
      console.log("handleEdge", event, slick, direction);
    },
    handleInit(event, slick) {
      console.log("handleInit", event, slick);
    },
    handleReInit(event, slick) {
      console.log("handleReInit", event, slick);
    },
    handleSetPosition(event, slick) {
      console.log("handleSetPosition", event, slick);
    },
    handleSwipe(event, slick, direction) {
      console.log("handleSwipe", event, slick, direction);
    },
    handleLazyLoaded(event, slick, image, imageSource) {
      console.log("handleLazyLoaded", event, slick, image, imageSource);
    },
    handleLazeLoadError(event, slick, image, imageSource) {
      console.log("handleLazeLoadError", event, slick, image, imageSource);
    },
  },
};
</script>
